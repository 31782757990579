<template>
	<div class="section">
		<div class="container">
			<h1>Thank you</h1>
			<p>
				Your quote has been submitted and is being processed. We will contact you promptly after
				reviewing the quote.
			</p>
			<br />
			<p v-if="$store.getters.isLoggedIn">
				<router-link v-show="$store.getters.isLoggedIn" to="/dashboard">
					View your dashboard
				</router-link>
			</p>
			<p>
				<router-link to="/rfq">Request another quote</router-link>
			</p>
		</div>
	</div>
</template>
<script>
	export default {
		setup() {
			return {};
		}
	};
</script>
