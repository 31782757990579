<template>
    <div>
        <grille-builder></grille-builder>
        <schedule-builder></schedule-builder>
    </div>
</template>
<script>
    import {
        getCurrentInstance,
        ref,
        computed,
        watch,
        onMounted,
        inject,
    } from "vue";
    import grilleBuilder from "@/pages/grilleBuilder.vue";
    import scheduleBuilder from "@/pages/scheduleBuilder.vue";
    export default {
        components: {
            "grilleBuilder": grilleBuilder,
            "scheduleBuilder": scheduleBuilder
        },
        setup() {
            return {}
        }
    }
</script>
