<template>
    <div :class="{
        section: true,
        gbSection: true,
        gbSectionCollapsed: collapsed,
        noTransition: transitionDisabled
    }">
        <div class="measurer">
            <div class="container">
                <h1>Grille Builder</h1>
                <o-button :icon-right="dropdownIcon" :class="{ gbCollapseButton: true, gbCollapseButtonCollapsed: collapsed }" @click="ToggleCollapse">
                    {{ collapsed ? "Show" : "Hide" }}
                </o-button>
                <div class="columns">
                    <div class="column indexColumn">
                        <o-field :class="{ selected: view === 0 }" v-if="step >= 0">
                            <o-button outlined @click="view = 0">{{ applicationText }}</o-button>
                        </o-field>
                        <o-field :class="{ selected: view === 1 }" v-if="step > 0">
                            <o-button outlined @click="view = 1">{{ typeText }}</o-button>
                        </o-field>
                        <o-field :class="{ selected: view === 2 }" v-if="step > 1">
                            <o-button outlined @click="view = 2">{{ styleText }}</o-button>
                        </o-field>
                        <o-field :class="{ selected: view === 3 }" v-if="step > 2 && isPerforated">
                            <o-button outlined @click="view = 3">{{ patternText }}</o-button>
                        </o-field>
                        <o-field :class="{ selected: view === 4 }" v-if="step > 3">
                            <o-button outlined @click="view = 4">{{ materialText }}</o-button>
                        </o-field>
                        <o-field :class="{ selected: view === 5 }" v-if="step > 4">
                            <o-button outlined @click="view = 5">{{ finishText }}</o-button>
                        </o-field>
                        <o-field :class="{ selected: view === 6 }" v-if="step > 5 && isPerforated && !isCustomFinish">
                            <o-button outlined @click="view = 6">{{ thicknessText }}</o-button>
                        </o-field>
                        <o-field :class="{ selected: view === 7 }" v-if="step > 6 && isLinear">
                            <o-button outlined @click="view = 7">{{ frameText }}</o-button>
                        </o-field>
                        <o-field :class="{ selected: view === 8 }" v-if="step > 7">
                            <o-button outlined @click="view = 8">{{ sizeText }}</o-button>
                        </o-field>
                        <o-field :class="{ selected: view === 9 }" v-if="step > 7">
                            <o-button outlined @click="view = 9">Options</o-button>
                        </o-field>
                        <o-field v-if="step > 7">
                            <o-button variant="success" icon-left="save" :disabled="grille.saving || !isComplete" v-if="$store.getters.isLoggedIn" @click="SaveGrille">
                                Save Grille
                            </o-button>
                        </o-field>
                        <o-field v-if="step > 7">
                            <o-button variant="success" :disabled="!isComplete" @click="AddToSchedule">
                                Add to schedule
                            </o-button>
                        </o-field>
                    </div>
                    <div class="column" v-if="loading">
                        <loading></loading>
                    </div>
                    <div class="column selectorColumn" v-else>
                        <h2>{{ viewTitle }}</h2>
                        <div v-if="showImage && view < 8">
                            <image-selector :data="currentOptions" :size="selectSize" @changed="SelectImage" @load="ImageLoaded"></image-selector>
                        </div>
                        <div v-if="!showImage && view < 8">
                            <text-selector :data="currentOptions" :size="selectSize" margin="8px" @changed="SelectImage"></text-selector>
                        </div>
                        <template v-if="view === 8">
                            <div class="gbStockSizes" v-if="isStock">
                                <div class="is-flex is-flex-wrap-wrap is-justify-content-flex-start" style="grid-area: dim">
                                    <o-button @click="SwitchToCustom">Need a custom size?</o-button>
                                    <label class="label gbBlock">In-stock dimensions</label>

                                    <div v-for="(size, index) in currentOptions" :key="index">
                                        <div :class="{
                                            'box mx-3 is-hoverable': true,
                                            'is-selected': grille.GrilleSizes.some((x) => x.Id == size.Id)
                                        }" @click="SelectSizes(size)">
                                            <span>{{ size.Height }}&quot;</span>
                                            x
                                            <span>{{ size.Length }}&quot;</span>
                                        </div>
                                    </div>
                                </div>

                                <div style="grid-area: qty">
                                    <div v-if="grille.GrilleSizes &&
                                        grille.GrilleSizes.filter((x) => x.Height > 0 && x.Length > 0).length > 0
                                        " style="position: sticky; top: 0">
                                        <div v-for="size in grille.GrilleSizes" :key="size.Id">
                                            <o-field :label="size.Height + 'x' + size.Length + ' Quantity'">
                                                <o-input type="number" placeholder="Quantity" v-model="size.Quantity"></o-input>
                                            </o-field>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <o-field label="Quantity">
                                            <o-input type="number" placeholder="Select a size first" disabled></o-input>
                                        </o-field>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <o-field v-if="!isLinear" class="column">
                                    <o-select v-model="measurementTypeId" placeholder="Select type" size="small">
                                        <option :value="1">Opening Measurement</option>
                                        <option :value="2">Overall Measurement</option>
                                    </o-select>
                                    <template v-slot:label>
                                        Measurement type
                                        <o-tooltip appendToBody position="right" variant="info" style="left: -0.7em">
                                            <template v-slot:content>
                                                <img :src="api + 'api/image/measurement-types.jpg'" style="max-width: none; width: 256px" />
                                            </template>
                                            <o-icon icon="question-circle" class="mx-2 my-2"></o-icon>
                                        </o-tooltip>
                                    </template>
                                </o-field>
                                <o-field v-if="isPerforated && isCustomFinish" label="Custom Thickness*" class="column is-4 customThicknessField">
                                    <o-input v-model="grille.GrilleOption.CustomThickness" placeholder="Thickness" size="small"></o-input>
                                </o-field>
                                <div v-if="grille.Type?.Id == 1 &&
                                    grille.GrilleFrame?.Id == 5 &&
                                    grille.GrilleFinish?.Id == 11 &&
                                    grille.GrilleMaterial?.Id == 1 &&
                                    (grille.GrilleStyle?.Id == 1 || grille.GrilleStyle?.Id == 5)
                                    ">
                                    <o-button @click="SwitchToStock">View in-stock sizes</o-button>
                                </div>
                                <o-field v-if="isLinear || isJBead" class="column">
                                    <label>
                                        <b>Measurement type:</b>
                                        Opening Measurement
                                        <o-tooltip appendToBody position="right" variant="info" style="left: -0.7em">
                                            <template v-slot:content>
                                                <img :src="api + 'api/image/measurement-types.jpg'" style="max-width: none; width: 256px" />
                                            </template>
                                            <o-icon icon="question-circle" class="mx-2 my-2"></o-icon>
                                        </o-tooltip>
                                    </label>
                                </o-field>

                                <div v-for="(size, index) in grille.GrilleSizes" :key="size" class="columns sizeRow">
                                    <o-field label="Height (in.)*" class="column">
                                        <o-input type="number" :min="0" :max="999999" step="any" v-model.number="size.Height" placeholder="Height" size="small"></o-input>
                                    </o-field>
                                    <o-field label="Length (in.)*" class="column">
                                        <o-input type="number" :min="0" :max="999999" step="any" v-model.number="size.Length" placeholder="Length" size="small"></o-input>
                                    </o-field>
                                    <o-field label="Qty.*" class="column is-2">
                                        <o-input type="number" min="1" v-model.number="size.Quantity" placeholder="Quantity" size="small"></o-input>
                                    </o-field>
                                    <o-field class="column" style="margin: auto auto">
                                        <o-button v-if="grille.GrilleSizes.length > 1" class="delete" @click="Remove(size, index)"></o-button>
                                    </o-field>
                                </div>
                                <a @click="
                                    grille.GrilleSizes.push({ MeasurementTypeId: isLinear ? 1 : measurementTypeId })
                                    ">
                                    Add another size
                                </a>
                            </div>
                        </template>
                        <div v-if="view === 9">
                            <br />
                            <o-field v-if="isLinear">
                                <o-checkbox v-model="grille.GrilleOption.HasDoor" size="medium">
                                    Access door?
                                </o-checkbox>
                                <template v-if="grille.GrilleOption.HasDoor">
                                    &nbsp;
                                    <span class="large-paren">(</span>
                                    &nbsp;
                                    <span class="inline-paren-break">
                                        <o-radio v-model="grille.GrilleOption.DoorOnRight" native-value="false">
                                            On the left
                                        </o-radio>
                                        <br />
                                        <o-radio v-model="grille.GrilleOption.DoorOnRight" native-value="true">
                                            On the right
                                        </o-radio>
                                    </span>
                                    &nbsp;
                                    <span class="large-paren">)</span>
                                </template>
                            </o-field>
                            <o-field v-if="isLinear">
                                <o-checkbox v-model="grille.GrilleOption.HasRemovableCore" size="medium">
                                    Removable core?
                                </o-checkbox>
                            </o-field>
                            <div v-if="isPerforated">
                                <o-field>
                                    <o-checkbox v-model="grille.GrilleOption.HasMountingHoles" size="medium">
                                        Mounting holes?
                                    </o-checkbox>
                                </o-field>
                                <o-field>
                                    <o-checkbox v-model="grille.GrilleOption.HasJBeadFrame" size="medium">
                                        J-Bead style frame?
                                    </o-checkbox>
                                </o-field>
                            </div>
                            <o-input type="textarea" v-model="grille.GrilleOption.SpecialRequirements" maxlength="185" placeholder="Optional: Special instructions for this grille."></o-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const Step = {
    Application: 0,
    Type: 1,
    Style: 2,
    Pattern: 3,
    Material: 4,
    Finish: 5,
    Thickness: 6,
    Frame: 7,
    Size: 8,
    Options: 9
};

import {
    getCurrentInstance,
    ref,
    computed,
    watch,
    onMounted,
    onUpdated,
    nextTick,
    inject
} from "vue";
import { imageSelector, textSelector } from "@/components";
import { GrilleType } from "@/enums";
export default {
    components: {
        imageSelector: imageSelector,
        textSelector: textSelector
    },
    setup() {
        const global = getCurrentInstance()?.appContext.config.globalProperties;
        const $http = inject("$http");
        const $httpGetCached = inject("$httpGetCached");
        const $success = inject("$success");
        const $error = inject("$error");
        const $mobileCheck = inject("$mobileCheck");

        const isMobile = $mobileCheck();

        const api = ref(process.env.VUE_APP_API);
        const loading = ref(false);
        const options = ref({
            applications: [],
            thicknesses: [],
            grilleTypes: [],
            grilleStyles: [],
            grillePatterns: [],
            grilleMaterials: [],
            grilleFrames: [],
            grilleFinishes: [],
            stockSizes: []
        });
        const grille = ref({
            GrilleSizes: [{ MeasurementTypeId: undefined }],
            GrilleOption: {}
        });

        const measurementTypeId = ref(undefined);
        const collapsed = ref(false);
        const dropdownIcon = computed(() => {
            return collapsed.value ? "chevron-down" : "chevron-up";
        });
        const measurerElement = ref({});
        const sectionElement = ref({});
        const sectionHeight = ref(0);

        const isLinear = computed(() => {
            return grille.value.Type?.Id === GrilleType.Linear;
        });
        const isPerforated = computed(() => {
            return grille.value.Type?.Id === GrilleType.Perforated;
        });
        const isJBead = computed(() => {
            return grille.value.Type?.Id === GrilleType.JBead;
        });
        const isStock = computed(() => {
            return grille.value?.Type?.Id === GrilleType.JBead || grille.value.Type?.Id === 4;
        });
        const isCustomFinish = computed(() => {
            return !!grille.value.GrilleFinish.Name.match(/custom/i);
        });
        const sizeIsComplete = computed(() => {
            return (
                grille.value.GrilleSizes.length > 0 &&
                grille.value.GrilleSizes.every((size) => {
                    if (isLinear.value) size.MeasurementTypeId = 1;
                    return size.MeasurementTypeId && size.Height && size.Length && size.Quantity;
                })
            );
        });
        const isComplete = computed(() => {
            return (
                step.value === Step.Options &&
                ((isLinear.value && grille.value.GrilleOption?.ApplicationId) ||
                    isPerforated.value ||
                    isJBead.value) &&
                sizeIsComplete.value &&
                (grille.value.GrilleOption.HasDoor == false ||
                    grille.value.GrilleOption.HasDoor == undefined ||
                    grille.value.GrilleOption.DoorOnRight != undefined)
            );
        });

        const view = ref(Step.Application);
        const showImage = computed(() => {
            let result = false;
            switch (view.value) {
                case Step.Application:
                case Step.Type:
                case Step.Style:
                case Step.Material:
                case Step.Pattern:
                case Step.Frame:
                case Step.Finish:
                    result = true;
                    break;
            }
            return result;
        });

        const keyValueSeparator = ": ";

        const typeIsSelected = computed(() => {
            return !!grille.value.Type?.Id;
        });
        const typeText = computed(() => {
            const name = "Type";
            if (grille.value.Type?.Id) {
                return name + keyValueSeparator + grille.value.Type.Name;
            } else {
                return name;
            }
        });

        const styleIsSelected = computed(() => {
            return !!grille.value.GrilleStyle?.Id;
        });
        const name = "Style";
        const styleText = computed(() => {
            if (grille.value.GrilleStyle?.Id) {
                return name + keyValueSeparator + grille.value.GrilleStyle.Name;
            } else {
                return name;
            }
        });

        const patternIsSelected = computed(() => {
            return !!grille.value.GrillePatterns;
        });
        const patternText = computed(() => {
            const name = "Pattern";
            if (grille.value.GrillePatterns) {
                return name + keyValueSeparator + grille.value.GrillePatterns.Name;
            } else {
                return name;
            }
        });

        const materialIsSelected = computed(() => {
            return !!grille.value.GrilleMaterial?.Id;
        });
        const materialText = computed(() => {
            const name = "Material";
            if (grille.value.GrilleMaterial?.Id) {
                return name + keyValueSeparator + grille.value.GrilleMaterial.Name;
            } else {
                return name;
            }
        });

        const thicknessIsSelected = computed(() => {
            return !!grille.value.GrilleThickness?.Id;
        });
        const thicknessText = computed(() => {
            const name = "Thickness";
            if (grille.value.GrilleThickness?.Id) {
                return name + keyValueSeparator + grille.value.GrilleThickness?.Name;
            } else {
                return name;
            }
        });

        const applicationIsSelected = computed(() => {
            return !!grille.value.GrilleOption?.Application?.Id;
        });
        const applicationText = computed(() => {
            const name = "Application";
            if (grille.value.GrilleOption?.Application?.Id) {
                return name + keyValueSeparator + grille.value.GrilleOption?.Application?.Name;
            } else {
                return name;
            }
        });

        const frameIsSelected = computed(() => {
            return !!grille.value.GrilleFrame?.Id;
        });
        const frameText = computed(() => {
            const name = "Frame";
            if (grille.value.GrilleFrame?.Id) {
                return name + keyValueSeparator + grille.value.GrilleFrame.Name;
            } else {
                return name;
            }
        });

        const finishIsSelected = computed(() => {
            return !!grille.value.GrilleFinish?.Id;
        });
        const finishText = computed(() => {
            const name = "Finish";
            if (grille.value.GrilleFinish?.Id) {
                return name + keyValueSeparator + grille.value.GrilleFinish.Name;
            } else {
                return name;
            }
        });

        const sizeIsSelected = computed(() => {
            return grille.value.GrilleSizes.length > 0;
        });
        const sizeText = computed(() => {
            let name = "Size";
            let text = "";
            if (grille.value.GrilleSizes.length === 1) {
                const size = grille.value.GrilleSizes[0];

                if (size.Height) {
                    text += size.Height + '"H';
                    if (size.Length) {
                        text += " x ";
                    }
                }
                if (size.Length) {
                    text += size.Length + '"L';
                }

                if (size.MeasurementTypeId) {
                    const measurementType =
                        size.MeasurementTypeId == 1 ? "Opening measurement" : "Overall measurement";
                    text += " " + measurementType;
                }

                if (size.Quantity) {
                    text += " | Quantity: " + size.Quantity;
                }
            } else if (grille.value.GrilleSizes.length > 1) {
                text = "Multiple sizes | Total Qty: ";
                let total = 0;
                for (const size of grille.value.GrilleSizes) {
                    if (size.Quantity) {
                        total += parseInt(size.Quantity);
                    }
                }
                text += total;
            } else {
                return name;
            }
            return name + keyValueSeparator + text;
        });

        const step = computed(() => {
            if (!applicationIsSelected.value) return Step.Application;
            else if (!typeIsSelected.value) return Step.Type;
            else if (!styleIsSelected.value) return Step.Style;
            else if (!patternIsSelected.value && isPerforated.value) return Step.Pattern;
            else if (!materialIsSelected.value) return Step.Material;
            else if ((isLinear.value || isJBead.value || isPerforated.value) && !finishIsSelected.value)
                return Step.Finish;
            else if (!thicknessIsSelected.value && isPerforated.value && !isCustomFinish.value)
                return Step.Thickness;
            else if (!frameIsSelected.value && isLinear.value) return Step.Frame;
            else if (!sizeIsComplete.value) return Step.Size;
            else return Step.Options;
        });
        const viewTitle = computed(() => {
            switch (view.value) {
                case Step.Type:
                    return "Select a Type";
                case Step.Style:
                    return "Select a Style";
                case Step.Pattern:
                    return "Select a Pattern";
                case Step.Material:
                    return "Select a Material";
                case Step.Thickness:
                    return "Select a Thickness";
                case Step.Application:
                    return "Where do you plan on installing your grille? (Select one)";
                case Step.Frame:
                    return "Select a Frame";
                case Step.Finish:
                    return "Select a Finish";
                case Step.Size:
                    return "What size grille?";
                default:
                    return "Additional Options";
            }
        });

        const excludeJBead = computed(() => {
            return !![1, 4, 5, 6, 7, 8].find((x) => x === grille.value.GrilleOption.ApplicationId);
        });
        const currentOptions = computed(() => {
            switch (view.value) {
                case Step.Type:
                    if (excludeJBead.value) {
                        return options.value.grilleTypes.filter((x) => x.Id != 5);
                    }
                    return options.value.grilleTypes;
                case Step.Style:
                    return options.value.grilleStyles.filter((x) => x.GrilleTypeId === grille.value.Type.Id);
                case Step.Pattern:
                    return options.value.grillePatterns;
                case Step.Material:
                    return options.value.grilleMaterials;
                case Step.Thickness:
                    return options.value.thicknesses;
                case Step.Application:
                    return options.value.applications;
                case Step.Frame:
                    return options.value.grilleFrames;
                case Step.Finish:
                    return options.value.grilleFinishes;
                case Step.Size:
                    return options.value.stockSizes;
                default:
                    return [];
            }
        });
        const selectSize = computed(() => {
            if (!isMobile) {
                switch (view.value) {
                    case Step.Type:
                    case Step.Frame:
                        return 16;
                    case Step.Application:
                        return 12;
                    case Step.Pattern:
                        return 14;
                    case Step.Thickness:
                        return 2;
                    default:
                        return 6;
                }
            } else {
                switch (view.value) {
                    case Step.Type:
                    case Step.Frame:
                        return 16;
                    case Step.Application:
                        return 20;
                    case Step.Pattern:
                        return 10;
                    case Step.Thickness:
                        return 2;
                    default:
                        return 10;
                }
            }
        });

        const SwitchToStock = async () => {
            grille.value.Type = options.value.grilleTypes.find((x) => x.Id == GrilleType.JBead);
            grille.value.TypeId = GrilleType.JBead;

            if (grille.value.GrilleStyle.Id == 1)
                grille.value.GrilleStyle = options.value.grilleStyles.find((x) => x.Id == 41);
            else if (grille.value.GrilleStyle.Id == 5)
                grille.value.GrilleStyle = options.value.grilleStyles.find((x) => x.Id == 42);

            var response = await $httpGetCached(
                "/api/grilleStyleType/" + grille.value.GrilleStyle.Id + "/material"
            );
            options.value.grilleMaterials = response.data;

            grille.value.GrilleMaterial = options.value.grilleMaterials.find((x) => x.Id == 7);
            var response = await $httpGetCached(
                "/api/grilleMaterialType/" + grille.value.GrilleMaterial.Id + "/finishes"
            );
            options.value.grilleFinishes = response.data;

            grille.value.GrilleFrame = options.value.grilleFrames.find((x) => x.Id == 5);

            grille.value.GrilleSizes = grille.value.GrilleSizes.filter((gs) => {
                const first = options.value.stockSizes.find(
                    (ss) => gs.Length == ss.Length && gs.Height == ss.Height
                );
                if (first !== undefined) {
                    gs.Id = first.Id;
                    gs.StyleId = first.StyleId;
                    return true;
                } else {
                    return false;
                }
            });
        };

        const SwitchToCustom = async () => {
            grille.value.Type = options.value.grilleTypes.find((x) => x.Id == 1);
            grille.value.TypeId = GrilleType.Linear;

            if (grille.value.GrilleStyle.Id == 41)
                grille.value.GrilleStyle = options.value.grilleStyles.find((x) => x.Id == 1);
            else if (grille.value.GrilleStyle.Id == 42)
                grille.value.GrilleStyle = options.value.grilleStyles.find((x) => x.Id == 5);

            var response = await $httpGetCached(
                "/api/grilleStyleType/" + grille.value.GrilleStyle.Id + "/material"
            );
            options.value.grilleMaterials = response.data;

            grille.value.GrilleMaterial = options.value.grilleMaterials.find((x) => x.Id == 1);
            var response = await $httpGetCached(
                "/api/grilleMaterialType/" + grille.value.GrilleMaterial.Id + "/finishes"
            );
            options.value.grilleFinishes = response.data;

            grille.value.GrilleFinish = options.value.grilleFinishes.find((x) => x.Id == 11);
            var response = await $httpGetCached(
                "/api/applicationType/" + grille.value.GrilleOption.Application?.Id + "/frames"
            );
            options.value.grilleFrames = response.data;
            grille.value.GrilleFrame = options.value.grilleFrames.find((x) => x.Id == 5);

            grille.value.GrilleSizes = JSON.parse(JSON.stringify(grille.value.GrilleSizes));

            if (grille.value.GrilleSizes.length == 0) {
                grille.value.GrilleSizes.push({});
            }
        };

        const UpdateSectionHeight = () => {
            window.requestAnimationFrame(() => {
                if (collapsed.value) {
                    sectionElement.value.style.height = "8em";
                } else {
                    const newHeight = measurerElement.value.offsetHeight;
                    if (sectionHeight.value !== newHeight) {
                        sectionHeight.value = newHeight;
                        TransitionTimeout(100);
                    }
                    sectionElement.value.style.height = `${sectionHeight.value}px`;
                }
            });
        };

        const ToggleCollapse = () => {
            collapsed.value = !collapsed.value;
            UpdateSectionHeight();
        };

        const SanitizeGrille = (g) => {
            const _trim = (o) => {
                if (typeof o === "string" || o instanceof String) {
                    return;
                }
                const keys = Object.keys(o);
                for (const key of keys) {
                    if (key === "selected") {
                        delete o[key];
                    } else {
                        const value = o[key];
                        if (value) {
                            _trim(value);
                        }
                    }
                }
            };

            _trim(g);

            const requiredChildren = [
                "GrilleFinish",
                "GrilleFrame",
                "GrilleMaterial",
                "GrilleOption",
                "GrillePatterns",
                "GrilleSizes",
                "GrilleStyle",
                "GrilleThickness"
            ];
            for (const childKey of requiredChildren) {
                if (!g[childKey]) {
                    g[childKey] = {};
                }
            }
        };

        const Reset = () => {
            grille.value.GrilleSizes = [{}];
            grille.value.GrilleOption.SpecialRequirements = undefined;
            grille.value.GrilleOption.HasDoor = undefined;
            grille.value.GrilleOption.DoorOnRight = undefined;
            grille.value.GrilleOption.HasRemovableCore = undefined;
            switch (view.value) {
                case Step.Application:
                    grille.value.GrilleOption.Application = undefined;
                    grille.value.Type = undefined;
                    grille.value.GrilleStyle = undefined;
                    grille.value.GrillePatterns = undefined;
                    grille.value.GrilleMaterial = undefined;
                    grille.value.GrilleFinish = undefined;
                    grille.value.GrilleThickness = undefined;
                    grille.value.GrilleFrame = undefined;
                    break;
                case Step.Type:
                    grille.value.Type = undefined;
                    grille.value.GrilleStyle = undefined;
                    grille.value.GrillePatterns = undefined;
                    grille.value.GrilleMaterial = undefined;
                    grille.value.GrilleFinish = undefined;
                    grille.value.GrilleThickness = undefined;
                    grille.value.GrilleFrame = undefined;
                    break;
                case Step.Style:
                    grille.value.GrilleStyle = undefined;
                    grille.value.GrillePatterns = undefined;
                    grille.value.GrilleMaterial = undefined;
                    grille.value.GrilleFinish = undefined;
                    grille.value.GrilleThickness = undefined;
                    grille.value.GrilleFrame = undefined;
                    break;
                case Step.Material:
                    grille.value.GrilleMaterial = undefined;
                    grille.value.GrilleFinish = undefined;
                    grille.value.GrilleThickness = undefined;
                    grille.value.GrilleFrame = undefined;
                    break;
                case Step.Finish:
                    grille.value.GrilleFinish = undefined;
                    grille.value.GrilleThickness = undefined;
                    grille.value.GrilleFrame = undefined;
                    break;
                case Step.Frame:
                    grille.value.GrilleFrame = undefined;
                    break;
            }
        };
        Reset();

        const SaveGrille = async () => {
            grille.value.saving = true;
            try {
                await $http.post("/api/grille", { grille: grille.value });
                grille.value.saving = false;
                Reset();
                $success("Saved grille!");
            } catch (err) {
                grille.value.saving = false;
                $error("Failed to save grille.");
            }
        };

        const PollForOptions = (attempts) => {
            const poll = (resolve, time, tries) => {
                setTimeout(() => {
                    if (currentOptions.value.length > 0) {
                        return resolve(true);
                    } else if (tries == 0) {
                        return resolve(false);
                    } else {
                        return poll(resolve, time, tries--);
                    }
                }, time);
            };
            const awaiter = new Promise((resolve, reject) => {
                poll(resolve, 100, attempts);
            });
            return awaiter;
        };

        const TryToSkipAhead = async (fromView, toView) => {
            await PollForOptions(10);
            if (currentOptions.value.length == 1) {
                await SelectImage(currentOptions.value[0]);
            }
        };

        const SelectSizes = async (selection) => {
            const currentView = view.value;

            if (grille.value.Type.Id === GrilleType.JBead) {
                selection.MeasurementTypeId = 1;

                grille.value.GrilleSizes = grille.value.GrilleSizes.filter((x) => x.Height >= 0);

                if (grille.value.GrilleSizes.findIndex((x) => x.Id == selection.Id) >= 0) {
                    grille.value.GrilleSizes.splice(
                        grille.value.GrilleSizes.findIndex((x) => x.Id == selection.Id),
                        1
                    );
                } else {
                    grille.value.GrilleSizes.push(selection);
                }
                view.value = Step.Size;
            } else {
                grille.value.GrilleSizes = JSON.parse(JSON.stringify([selection]));
                view.value = Step.Size;
                await TryToSkipAhead(currentView, view.value);
            }
        };

        const SelectImage = async (selection) => {
            const currentView = view.value;
            Reset();
            switch (view.value) {
                case Step.Application:
                    grille.value.GrilleOption.Application = selection;
                    grille.value.GrilleOption.ApplicationId = selection.Id;
                    view.value = Step.Type;
                    await TryToSkipAhead(currentView, view.value);
                    break;
                case Step.Type:
                    grille.value.Type = selection;
                    grille.value.TypeId = selection?.Id;
                    view.value = Step.Style;
                    await TryToSkipAhead(currentView, view.value);
                    break;
                case Step.Style:
                    grille.value.GrilleStyle = selection;
                    if (grille.value.TypeId === 5) {
                        await GetStockSizes(grille.value.GrilleStyle.Id);
                    }
                    if (isLinear.value || isJBead.value) {
                        view.value = Step.Material;
                    } else {
                        view.value = Step.Pattern;
                    }
                    await GetMaterialOptions();
                    await TryToSkipAhead(currentView, view.value);
                    break;
                case Step.Pattern:
                    grille.value.GrillePatterns = selection;
                    view.value = Step.Material;
                    await TryToSkipAhead(currentView, view.value);
                    break;
                case Step.Material:
                    grille.value.GrilleMaterial = selection;
                    view.value = Step.Finish;
                    await TryToSkipAhead(currentView, view.value);
                    break;
                case Step.Thickness:
                    grille.value.GrilleThickness = selection;
                    view.value = Step.Size;
                    await TryToSkipAhead(currentView, view.value);
                    break;
                case Step.Frame:
                    grille.value.GrilleFrame = selection;
                    view.value = Step.Size;
                    await TryToSkipAhead(currentView, view.value);
                    break;
                case Step.Finish:
                    grille.value.GrilleFinish = selection;
                    if (isPerforated.value) {
                        if (!isCustomFinish.value) {
                            view.value = Step.Thickness;
                            await GetThicknessOptions();
                        } else {
                            view.value = Step.Size;
                        }
                    } else if (isJBead.value) {
                        view.value = Step.Size;
                    } else {
                        view.value = Step.Frame;
                    }
                    await TryToSkipAhead(currentView, view.value);
                    break;
                case Step.Size:
                    if (grille.value.Type.Id === GrilleType.JBead) {
                        selection[0].MeasurementTypeId = 1;
                    }
                    grille.value.GrilleSizes = JSON.parse(JSON.stringify(selection));
                    view.value = Step.Size;
                    await TryToSkipAhead(currentView, view.value);
                    break;
            }
        };

        const GetPatternOptions = async () => {
            loading.value = true;
            try {
                var response = await $httpGetCached(
                    "/api/grilleStyleType/" + grille.value.GrilleStyle.Id + "/patterns/details"
                );
                options.value.grillePatterns = response.data;
            } catch (err) {
                $error("Failed to get patterns.");
            }
            loading.value = false;
        };
        const GetMaterialOptions = async () => {
            loading.value = true;
            try {
                var response = await $httpGetCached(
                    "/api/grilleStyleType/" + grille.value.GrilleStyle.Id + "/material"
                );
                options.value.grilleMaterials = response.data;
            } catch (err) {
                $error("Failed to get materials.");
            }
            loading.value = false;
        };
        const GetFrameOptions = async () => {
            loading.value = true;
            try {
                var response = await $httpGetCached(
                    "/api/applicationType/" + grille.value.GrilleOption.Application?.Id + "/frames"
                );
                options.value.grilleFrames = response.data;
            } catch (err) {
                $error("Failed to get frames.");
            }
            loading.value = false;
        };
        const GetFinishOptions = async () => {
            loading.value = true;
            try {
                var response = await $httpGetCached(
                    "/api/grilleMaterialType/" + grille.value.GrilleMaterial.Id + "/finishes"
                );
                options.value.grilleFinishes = response.data;
            } catch (err) {
                $error("Failed to get finishes.");
            }
            loading.value = false;
        };
        const GetThicknessOptions = async () => {
            loading.value = true;
            try {
                var response = await $httpGetCached(
                    "/api/grilleThicknessType/material/" + grille.value.GrilleMaterial.Name
                );
                options.value.thicknesses = response.data;
            } catch (err) {
                $error("Failed to get thicknesses.");
            }
            loading.value = false;
        };
        const GetStockSizes = async (styleId) => {
            loading.value = true;
            try {
                var response = await $httpGetCached(
                    "/api/grilleStyleType/" + grille.value.GrilleStyle.Id + "/stock/sizes"
                );
                options.value.stockSizes = response.data;
            } catch (err) {
                $error("Failed to get stock sizes.");
            }
            loading.value = false;
        };

        const GetOptions = async () => {
            loading.value = true;
            try {
                var promises = [
                    $httpGetCached("/api/grilleType"),
                    $httpGetCached("/api/grilleStyleType"),
                    $httpGetCached("/api/applicationType")
                ];
                var response = await Promise.all(promises);
                options.value.grilleTypes = response[0].data;
                options.value.grilleStyles = response[1].data;
                options.value.applications = response[2].data;
            } catch (err) {
                $error("Failed to load options. Please refresh the page.");
            }
            loading.value = false;
        };
        GetOptions();

        const Remove = (item, index) => {
            grille.value.GrilleSizes.splice(index, 1);
        };

        const AddToSchedule = () => {
            SanitizeGrille(grille.value);
            global.$store.dispatch("addGrille", JSON.parse(JSON.stringify(grille.value)));
            view.value = Step.Application;
            Reset();
        };

        watch(
            () => view.value,
            (n) => {
                if (n === Step.Pattern) {
                    GetPatternOptions();
                } else if (n === Step.Material) {
                    GetMaterialOptions();
                } else if (n === Step.Frame) {
                    GetFrameOptions();
                } else if (n === Step.Finish) {
                    GetFinishOptions();
                } else if (n === Step.Thickness) {
                    GetThicknessOptions();
                }
            }
        );

        const SyncSizeTypes = () => {
            for (const size of grille.value.GrilleSizes) {
                if (!measurementTypeId.value) measurementTypeId.value = 1;

                if (size.MeasurementTypeId != measurementTypeId.value) {
                    size.MeasurementTypeId = measurementTypeId.value;
                }
            }
        };

        const transitionDisabled = ref(false);

        const TransitionTimeout = (t) => {
            transitionDisabled.value = true;
            window.setTimeout(() => {
                transitionDisabled.value = false;
            }, t);
        };

        const ImageLoaded = () => {
            UpdateSectionHeight();
        };

        onMounted(async () => {
            sectionElement.value = document.querySelector(".gbSection");
            measurerElement.value = document.querySelector(".measurer");

            //const storedStyle = sectionElement.value.style.transition;
            //sectionElement.value.style.transition = "none";
            //window.setTimeout(() => {
            //    sectionElement.value.style.transition = storedStyle;
            //}, 500);
            //window.setInterval(UpdateSectionHeight, 100);
            //window.setTimeout(() => {
            //    TransitionTimeout(timeoutTime);
            //    UpdateSectionHeight();
            //}, 1000);

            window.setInterval(UpdateSectionHeight, 1000);

            const val = grille.value.GrilleSizes[0]?.MeasurementTypeId;
            if (val || val === 0) {
                measurementTypeId.value = val;
                SyncSizeTypes();
            } else {
                grille.value.GrilleSizes[0] = {
                    MeasurementTypeId: undefined,
                    Quantity: 1
                };
            }
            loading.value = false;
        });

        watch(
            () => view.value,
            (n, o) => {
                UpdateSectionHeight();
            }
        );
        watch(
            () => grille.value.GrilleSizes.length,
            (n, o) => {
                UpdateSectionHeight();
            }
        );
        watch(
            () => grille.value.GrilleSizes,
            (n, o) => {
                for (var i = 0; i < n.length; i++) {
                    if (o[i] && n[i].MeasurementTypeId != o[i].MeasurementTypeId) {
                        SyncSizeTypes();
                        break;
                    }
                }
            },
            { deep: true }
        );

        watch(
            () => measurementTypeId.value,
            (n, o) => {
                if (n !== o) {
                    SyncSizeTypes();
                }
            }
        );

        return {
            isMobile,
            api,
            loading,
            grille,
            measurementTypeId,
            collapsed,
            dropdownIcon,
            view,
            step,
            showImage,
            viewTitle,
            typeIsSelected,
            typeText,
            styleIsSelected,
            styleText,
            patternIsSelected,
            patternText,
            materialIsSelected,
            materialText,
            thicknessIsSelected,
            thicknessText,
            applicationIsSelected,
            applicationText,
            frameIsSelected,
            frameText,
            finishIsSelected,
            finishText,
            sizeIsSelected,
            sizeText,
            currentOptions,
            isLinear,
            isPerforated,
            isJBead,
            isStock,
            isCustomFinish,
            isComplete,
            selectSize,
            ToggleCollapse,
            Reset,
            SelectImage,
            SelectSizes,
            SwitchToCustom,
            SwitchToStock,
            Remove,
            AddToSchedule,
            transitionDisabled,
            ImageLoaded,
            SaveGrille
        };
    }
};
</script>
<style lang="scss">
@import "@/assets/css/globalVars.scss";
@import "@/assets/css/scheduleBuilderVars.scss";

.indexColumn {
    max-width: 26em;
}

.field button {
    width: 100%;
}

$outline-color: $primary;
$outline-width: 2px;
$outline-offset: 2px;
$arrow-width: 0.45em;

@mixin outline {
    outline: $outline-width solid $outline-color; //#52688F
    outline-offset: $outline-offset;
}

.selectorColumn {
    @include outline;
    background-color: white;

    & a {
        display: block;
        margin-top: -2em;
        font-size: 0.8em;
    }

    & .field.has-addons {
        align-items: center;
    }

    & .inline-paren-break {
        display: inline-block;
    }

    & .large-paren {
        font-size: 3em;
        line-height: 0;
    }

    @media screen and (min-width: 48.1rem) {
        max-height: 30em;
        overflow-y: auto;
    }
}

.customThicknessField {
    margin-top: -1em;
    margin-bottom: 1em;
}

.sizeRow {
    margin-bottom: 0;
}

.gbBlock {
    display: block;
    width: 100%;
}

.gbCollapseButton {
    $btnHeight: 2.6rem;
    $btnWidth: #{$btnHeight * 3};
    display: inline-block;
    position: absolute;
    z-index: 2;
    margin-top: 1rem;
    margin-left: 1em;
    box-sizing: border-box;
    //width: $btnWidth;
    //height: $btnHeight;
    background-color: white;
    color: $black;

    &.gbCollapseButtonCollapsed {
        //background-color: $primary;
        //color: white;
        //bottom: -$btnHeight;
    }

    &:hover {
        background-color: #fafafa;
        color: $black;
    }
}

.gbStockSizes {
    display: grid;
    grid-template-columns: 1fr 10rem;
    grid-template-areas: "dim qty";

    @media screen and (max-width: 40rem) {
        grid-template-columns: 1fr;
        grid-template-areas: "qty" "dim";
        grid-row-gap: 1em;
    }
}

.noTransition {
    transition: height 0s !important;
}

.section.gbSection {
    position: relative;
    transition: height 0.25s ease-out 0s;
    overflow: hidden;
    padding: 0;
    background-color: #f2f2f2;

    & h1 {
        display: inline-block;
    }

    & .measurer {
        padding: 3em 0 6em 0;
    }

    &.gbSectionCollapsed {
        overflow: hidden;
    }

    @media screen and (max-width: $break-large) {
        & .columns {
            display: block;

            & .column {
                margin: auto;

                &.selectorColumn {
                    margin: 0 1em;
                }
            }
        }
    }

    @media screen and (max-width: $break-small) {
        & .columns .column.indexColumn {
            margin: 0 1em;
        }
    }
}

.field.selected {
    @include outline;
    position: relative;

    & button {
        background-color: #aaa;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: calc(50% - $arrow-width);
        right: calc(#{-$arrow-width * 2} - #{$outline-offset + $outline-width / 2});
        height: 0;
        border: $arrow-width solid transparent;
        border-left-color: $outline-color;

        @media screen and (max-width: $break-large) {
            right: calc(50% - $arrow-width);
            top: unset;
            bottom: calc(#{-$arrow-width * 2} - #{$outline-offset + $outline-width / 2});
            border: $arrow-width solid transparent;
            border-top-color: $outline-color;
        }
    }
}
</style>
<style lang="scss" scoped>
.box {
    width: 6rem;
    text-align: center;
}

.is-flex {
    row-gap: 1rem;
}
</style>
