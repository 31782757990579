<template>
    <div class="section">
        <div class="container">
            <h1>Schedule Builder</h1>
            <loading v-if="loading"></loading>
            <div class="schedule">
                <o-button class="addNewGrille" @click="NewGrille(false)">
                    <o-icon icon="plus"></o-icon>
                    New grille
                </o-button>
                <schedule-grille v-for="(grille, index) in grilles" :key="grille" v-model="grilles[index]" @delete="Delete(grille)" @duplicate="Duplicate(grille)" @sbInput="OnInput"></schedule-grille>
                <o-button v-if="grilles.length > 4" class="addNewGrille" @click="NewGrille(true)">
                    <o-icon icon="plus"></o-icon>
                    New grille
                </o-button>
            </div>
            <form class="schedule-buttons">
                <o-button icon-left="quote-right" variant="primary" :disabled="(!isValid && isDirty) || !hasGrille" @click="Send()">
                    Request quote for this schedule
                </o-button>

                <o-button icon-left="file-download" variant="light" :disabled="specProcessing || !isValid" @click="GetRapidSpec()">
                    <span v-if="!specProcessing">Download spec for this schedule</span>
                    <loading v-else></loading>
                </o-button>
                <o-tooltip variant="primary" :active="!$store.getters.isLoggedIn">
                    <template v-slot:content>You must login to save the schedule.</template>
                    <o-button icon-left="save" variant="primary" :disabled="saving || !$store.getters.isLoggedIn || (!isValid && isDirty)" @click="Save()">
                        Save schedule
                    </o-button>
                </o-tooltip>
                <a :href="hasStockGrilles" target="_blank" v-if="hasStock">
                    <o-button variant="info">Purchase in-stock grilles</o-button>
                </a>
            </form>
        </div>
    </div>
</template>
<script>
import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
import { ScheduleGrille } from "@/components/schedule";
import { Name, Contact } from "@/components/modal";
import { docExport } from "@/services/utils";

export default {
    components: {
        "schedule-grille": ScheduleGrille
    },
    setup(props, context) {
        const global = getCurrentInstance()?.appContext.config.globalProperties;
        const $http = inject("$http");
        const $success = inject("$success");
        const $error = inject("$error");

        const api = ref(process.env.VUE_APP_API);
        const loading = ref(true);
        const saving = ref(false);
        const specProcessing = ref(false);

        const filter = ref({
            typeId: null,
            styleId: null,
            materialId: null,
            finish: null,
            frameId: null
        });

        const grilles = global.$store.getters.grilles;

        const IsPerforated = (grille) => {
            return grille.TypeId === 2;
        };

        const isDirty = computed(() => {
            return grilles.every((x) => x.IsDirty);
        });
        const isValid = computed(() => {
            return grilles.every((x) => x.IsValid);
        });
        const hasGrille = computed(() => {
            return grilles.length > 0;
        });

        const hasStock = computed(() => {
            var hasStock = false;
            grilles.forEach(function (entry) {
                entry.GrilleSizes.forEach(function (childrenEntry) {
                    if (!childrenEntry.VariantId) {
                        return hasStock;
                    }
                    hasStock = true;
                });
            });
            return hasStock;
        });
        const hasStockGrilles = computed(() => {
            var variantCount = 0;
            var shopifyUrl = "https://17d79d.myshopify.com/cart/";
            grilles.forEach(function (entry) {
                entry.GrilleSizes.forEach(function (childrenEntry) {
                    if (!childrenEntry.VariantId) {
                        return;
                    }
                    shopifyUrl += childrenEntry.VariantId + ":" + childrenEntry.Quantity + ",";
                    variantCount++;
                })
            })
            return shopifyUrl.substring(0, shopifyUrl.length - 1);
        });

        const IsFormValid = () => {
            grilles.forEach((g) => {
                g.Touch();
            });
            return isValid.value;
        };

        const GetRapidSpec = async () => {
            specProcessing.value = true;
            var response = await $http.post("/api/grilleSchedule/rapidspec", {
                grilles: grilles
            });
            docExport.CreateDocument(response.data, "Rapid Spec.docx");
            specProcessing.value = false;
        };

        const NewGrille = (push) => {
            const newGrille = {};
            newGrille.Type = { isValid: false };
            newGrille.GrilleFrame = {};
            newGrille.GrilleStyle = {};
            newGrille.GrilleMaterial = {};
            newGrille.GrilleFinish = {};
            newGrille.GrilleSizes = [
                {
                    MeasurementTypeId: 1,
                    Quantity: 1
                }
            ];
            newGrille.GrilleThickness = {};
            newGrille.GrilleOption = {};
            newGrille.IsValid = false;

            if (push) {
                grilles.push(newGrille);
            } else {
                grilles.unshift(newGrille);
            }
        };

        const Delete = (grille) => {
            const i = grilles.indexOf(grille);
            grilles.splice(i, 1);
            global.$store.dispatch("setGrilles", grilles);
        };

        const Edit = (row) => {
            const i = grilles.indexOf(row);
            grilles.splice(i, 1);
            global.$store.dispatch("setGrilles", grilles);
            sessionStorage.setItem("editGrille", JSON.stringify(row));

            global.$router.push({ name: "rfq" });
        };

        const SanitizeGrille = (grille) => {
            grille.Id = 0;
            if (grille.GrilleOption?.GrilleId) {
                grille.GrilleOption.GrilleId = 0;
            }
            if (grille.GrillePatterns) {
                grille.GrillePatterns.GrilleId = 0;
                grille.GrillePatterns.Detail = undefined;
                grille.GrillePatterns.Pattern = undefined;
                grille.GrillePatterns.Size = undefined;
            }

            for (let i = 0; i < grille.GrilleSizes.length; i++) {
                const size = grille.GrilleSizes[i];
                if (size.GrilleId) {
                    size.GrilleId = 0;
                }
                if (!size.MeasurementType) {
                    size.MeasurementType = {
                        Id: 1
                    };
                }
            }
        };

        const SanitizeGrilles = () => {
            for (const grille of grilles) {
                SanitizeGrille(grille);
            }
        };

        const Duplicate = (grille) => {
            const copy = JSON.parse(JSON.stringify(grille));
            SanitizeGrille(copy);
            grilles.push(copy);
            global.$store.dispatch("setGrilles", grilles);
        };

        const isInTimeout = ref(false);
        const tickLength = 50;
        const tickMax = 20;
        const tickCounter = ref(0);
        const Debounce = () => {
            if (tickCounter.value > 0) {
                setTimeout(Debounce, tickLength);
            } else {
                isInTimeout.value = false;
                global.$store.dispatch("setGrilles", grilles);
            }
            --tickCounter.value;
        };
        const OnInput = () => {
            tickCounter.value = tickMax;
            if (!isInTimeout.value) {
                isInTimeout.value = true;
                setTimeout(Debounce, tickLength);
            }
        };

        const PostSchedule = (name) => {
            SanitizeGrilles();
            return $http.post("/api/grilleSchedule", {
                name: name,
                grilles: grilles
            });
        };

        const TrySave = async (result) => {
            try {
                saving.value = true;
                await PostSchedule(result.name);
                result.close();
                global.$store.dispatch("setGrilles", []);
                $success("Grille Schedule saved!");
            } catch (err) {
                $error("Failed to save");
            }
        };

        const Save = () => {
            SanitizeGrilles();
            if (IsFormValid()) {
                if (isValid.value) {
                    global.$oruga.modal.open({
                        parent: this,
                        component: Name,
                        trapFocus: true,
                        active: true,
                        props: {
                            title: "Name Grille Schedule",
                            description: "Set a name to easily find this again."
                        },
                        events: {
                            save: (result) => {
                                TrySave(result);
                            },
                            close: () => {
                                saving.value = false;
                            }
                        }
                    });
                }
            }
        };

        const SendQuote = (contact) => {
            SanitizeGrilles();
            return $http.post("/api/quote", {
                grilles: grilles,
                contact: contact
            });
        };

        const TrySend = async (result) => {
            try {
                result.loading.value = true;
                await SendQuote(result.contact);
                result.close();
                global.$store.dispatch("setGrilles", []);
                $success("Quote has been submitted!");
                global.$router.push({ name: "thankyou" });
            } catch (err) {
                result.loading.value = false;
                $error("Failed to send quote");
            }
        };

        const Send = () => {
            if (IsFormValid()) {
                global.$oruga.modal.open({
                    parent: this,
                    component: Contact,
                    trapFocus: true,
                    active: true,
                    props: {
                        title: "Request a Quote",
                        description: "Let us know how to reach you.",
                        grilles: grilles
                    },
                    events: {
                        send: (result) => {
                            TrySend(result);
                        }
                    }
                });
            }
        };

        const TotalAmount = (row) => {
            let total = 0;
            for (const size of row.GrilleSizes) {
                if (size.Quantity) {
                    total += parseInt(size.Quantity);
                }
            }
            return total;
        };

        onMounted(async () => {
            loading.value = false;
        });

        return {
            api,
            loading,
            saving,
            specProcessing,
            grilles,
            filter,
            isValid,
            isDirty,
            hasGrille,
            hasStock,
            hasStockGrilles,
            Delete,
            Edit,
            Duplicate,
            OnInput,
            Save,
            Send,
            GetRapidSpec,
            NewGrille,
            TotalAmount,
            IsPerforated
        };
    }
};
</script>
<style lang="scss">
@import "@/assets/css/globalVars.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "@/assets/css/scheduleBuilderVars.scss";

@mixin dsc-title {
    font-size: 0.8rem;
    font-weight: bold;
    color: #{$info} !important;
}

$break-medium: 38rem;
$break-large: 64rem;

$column-gap: 2.5em;

.schedule-buttons {
    grid-template-columns: 1fr;
    display: grid;
    grid-gap: 0.5em;
    margin: 0 auto $column-gap auto;
    width: 100%;

    & button,
    & div button,
    & div>span.b-tooltip {
        width: 100%;
    }

    &:last-child {
        margin-top: $column-gap;
    }

    @media screen and (min-width: $break-medium) {
        width: 33.8em;
    }

    @media screen and (min-width: $break-large) {
        grid-template-columns: 1fr 1fr 1fr;
        width: 57.6em;
    }
}

.schedule {
    $heading-radius: 1em;
    $schedule-heading-color: #e6e7ec;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 5em;
    padding: 0;
    border: none;
    border-radius: 0;
    //background-color: $schedule-heading-color;
    //background-image: $pattern-overlay-diamonds-dark; //$schedule-table-bg;
    //box-shadow: $shadow;

    & .addNewGrille {
        align-self: stretch;
        box-shadow: $shadow;
        border-radius: $radius-large;
        box-sizing: border-box;
        width: $article-width;
        height: auto;
        background-color: #f2f2f2;
        color: #71737e;
        //filter: drop-shadow(0.1em 0.1em 0.1em rgb(0 0 0 / 30%));

        & .button-wrapper {
            font-size: 2em;
        }

        & .icon {
            display: block;
            margin: auto !important;
        }

        &:hover {
            background-color: #e2e2e2;
        }
    }

    & article.grille-wrap {
        display: flex;
        flex-flow: column;
        justify-items: flex-start;
        justify-content: space-between;
        border: 1px solid #dadada;
        border-radius: $radius-large;
        width: $article-width;
        //max-height: 33em;
        overflow-y: hidden;
        overflow-x: hidden;
        margin: 0;
        box-shadow: $shadow;
        background-color: #fff;

        & form.grille {
            display: flex;
            flex-flow: column;
            flex-grow: 1;
            justify-items: flex-start;
            gap: $inner-gap-y;
            max-height: 33em;
            overflow-y: auto !important;
            overflow-x: hidden;
            scrollbar-width: thin;
            //margin: $grid-gap-y -0.3em 0 0.2em;
            padding: 0.6em 0.4em 0.3em 0.4em;
            background-color: #fff;
        }
    }

    & div.sizeInput {
        display: flex;
        flex-direction: row;
        margin-top: $inner-gap-y;
        gap: $inner-gap-x;
        width: 100%;

        &:first-child {
            margin-top: 0;
        }
    }

    & td>.inputWrap {
        margin-top: $duplicate-gap;

        &:first-child {
            margin-top: 0;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    @media screen and (max-width: $break-large) {
        justify-content: space-around;
    }
}
</style>
